<!--

      This component is the foundation for four pages:
      1. Login
      2. Registration
      4. Reset Password
      3. New Password

 -->

<template>
  <Page 
    :scroll="false" 
    :hideheader="true" 
    :hidelargeheader="true" 
    :noPaddingBottom="true"
    :noPaddingTop="true"
  >
    <img src="../assets/img/bg-img.jpeg" class="bg-img" alt="">
    <div class="bg-color-overlay"></div>
    <div class="signinup-content-area">
      <div class="header-area"></div>
      <Icon name="foodableLogo" class="foodable-logo" />
      <div class="button-area">
        <slot></slot>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'

export default {
  name: 'signinuppage',
  components: {
    Page
  }
}
</script>

<style lang="scss" scoped>
@import './../theme/main.scss';

.bg-color-overlay {
  background: rgba(255,255,255,.3);
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
}

.bg-img {
  object-fit: cover;
  object-position: left;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
}

.signinup-content-area {
  width: 100vw;
  height: 100vh;
  padding-top: env(safe-area-inset-top);
  padding-top: constant(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: constant(safe-area-inset-bottom);
  position: relative;
  text-align: center;
}

.header-area {
  height: 8vh;
  position: relative;
}

.button-area {
  height: 65vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.foodable-logo {
  width: 40vw;
  margin: 0 auto;
}

</style>
